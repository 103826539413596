// routes
import { PATH_AUTH } from "../routes/paths";
// utils
import axios from "../utils/axios";

// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;
  // console.log("exp =>", exp);
  // console.log("currentTime =>", currentTime);
  // console.log("expiredTimer =>", expiredTimer);
  // const timeLeft = 5000;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    console.log("check accessToken on localStorage, token expired zaman gecti");
    localStorage.removeItem("accessToken");

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");

    delete axios.defaults.headers.common.Authorization;
  }
};

export const encryptData = async (params) => {
  const baseURL = process.env.REACT_APP_WS_ESHOP_BASE_URL;
  try {
    const encriptedData = await axios.post(
      `${baseURL}/standalone-service-util/encrypt-this-json-object-as-url-safe`,
      params
    );
    const { data: encrpytedParams } = encriptedData;
    return encrpytedParams; // Extract the encrypted data from the response
  } catch (error) {
    console.error("Error encrypting data:", error);
    throw error;
  }
};

export const getAllowedProjects = async (accessToken) => {
  try {
    const encriptedData = await axios.post(`authentication/verify-token`, {
      accessOrRefreshToken: accessToken,
    });
    const { data: encrpytedParams } = encriptedData;
    return encrpytedParams; // Extract the encrypted data from the response
  } catch (error) {
    console.error("Error encrypting data:", error);
    throw error;
  }
};
export const getAllProjects = async () => {
  try {
    const response = await axios.get(
      `project-domains/authentication-status/true`
    );
    const data = response.data;
    return data; // Extract the encrypted data from the response
  } catch (error) {
    console.error("Project List Error:", error);
    throw error;
  }
};
