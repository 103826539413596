// ----------------------------------------------------------------------

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// redux
// import { store, persistor } from "./redux/store";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";

import { MotionLazyContainer } from "./components/animate";
// import { ThemeSettings, SettingsProvider } from "./components/settings";

// Check our docs
// https://docs.minimals.cc/authentication/js-version

import { AuthProvider } from "./auth/JwtContext";
// import { AuthProvider } from './auth/Auth0Context';
// import { AuthProvider } from './auth/FirebaseContext';
// import { AuthProvider } from './auth/AwsCognitoContext';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <AuthProvider>
          <MotionLazyContainer>
            <ThemeProvider>
              <Router />
            </ThemeProvider>
          </MotionLazyContainer>
        </AuthProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
