import PropTypes from "prop-types";

// components
import LoadingScreen from "../components/loading-screen";
//
import { useAuthContext } from "./useAuthContext";

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isInitialized } = useAuthContext();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
