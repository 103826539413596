import PropTypes from "prop-types";
import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from "react";
// utils
import axios from "../utils/axios";
import localStorageAvailable from "../utils/localStorageAvailable";
//
import {
  isValidToken,
  setSession,
  encryptData,
  getAllowedProjects,
} from "./utils";
import { useNavigate } from "react-router-dom";
import { PATH_AUTH } from "src/routes/paths";
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  isProjectListEmpty: false,
  loading: false,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIAL":
      return {
        isInitialized: true,
        isAuthenticated: false,
        user: action.payload.user,
      };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "REGISTER":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case "NO_PROJECT":
      return {
        ...state,
        isProjectListEmpty: true,
      };
    case "SET_PROJECT":
      return {
        ...state,
        isProjectListEmpty: false,
      };
    case "LOADING":
      return { ...state, loading: true };
    case "LOADED":
      return { ...state, loading: false };
    case "ERROR":
      return {
        ...state,
        error: action.payload.error, // Set the error message
      };
    case "CLEAR_ERROR":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable
        ? localStorage.getItem("accessToken")
        : "";

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      } else {
        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "INITIAL",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  const clearError = useCallback(() => {
    dispatch({ type: "CLEAR_ERROR" });
    dispatch({ type: "SET_PROJECT" });
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN

  // Request interceptor
  axios.interceptors.request.use(
    (config) => {
      // Show the loader when a request is made
      dispatch({ type: "LOADING" });
      return config;
    },
    (error) => {
      // Hide the loader on request error
      dispatch({ type: "LOADED" });
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axios.interceptors.response.use(
    (response) => {
      // Hide the loader when a response is received
      dispatch({ type: "LOADED" });
      return response;
    },
    (error) => {
      // Hide the loader on response error
      dispatch({
        type: "ERROR",
        payload: { error: error.response.data.error.message || error.message },
      });
      dispatch({ type: "LOADED" });
      return Promise.reject(error);
    }
  );

  const login = useCallback(
    async (email, password) => {
      const response = await axios.post(`/authentication/sign-in`, {
        email,
        password,
      });

      const { accessToken, refreshToken } = response.data;
      const { allowed_projects: projectList } = await getAllowedProjects(
        accessToken
      );

      const callbackUrlParam = localStorage.getItem("callbackUrl");

      setSession(accessToken);

      const isCallbackparamUrlinProjectList = projectList.some(
        (item) => item.fqdn_root === callbackUrlParam
      );

      if (isCallbackparamUrlinProjectList) {
        const params = {
          accessToken,
          refreshToken,
          callbackUrl: callbackUrlParam || undefined,
        };

        const encryptedParams = await encryptData(params);
        window.location.replace(
          `https://${callbackUrlParam}/login?params=${encryptedParams}`
        );
      } else if (projectList.length === 1) {
        const domain = projectList[0]?.fqdn_root;
        const params = {
          accessToken,
          refreshToken,
          callbackUrl: domain || undefined,
        };

        const encryptedParams = await encryptData(params);
        window.location.replace(
          `https://${domain}/login?params=${encryptedParams}`
        );
      } else if (projectList.length > 1) {
        // Redirect to the "project-list" page with state
        navigate(PATH_AUTH.projectsList, {
          state: { projectList, accessToken, refreshToken },
        });
      } else if (projectList.length < 1 || !isCallbackparamUrlinProjectList) {
        dispatch({ type: "NO_PROJECT" });
      } else {
        // Handle the case when none of the conditions are met
        // You can add appropriate logic here.
      }
    },
    [navigate]
  );

  // REGISTER
  const register = useCallback(
    async (email, password) => {
      // const baseURL = process.env.REACT_APP_WS_ESHOP_BASE_URL;
      const response = await axios.post(`/authentication/sign-up`, {
        email,
        password,
      });
      if (response.status === 201) {
        setTimeout(() => {
          navigate(PATH_AUTH.login);
        }, 1500);
      }
      // dispatch({
      //   type: "REGISTER",
      //   payload: {
      //     user,
      //   },
      // });
    },
    [navigate]
  );

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: "LOGOUT",
    });
  }, []);

  // Google Auth
  const googleLogin = useCallback(
    (response) => {
      const baseURL = process.env.REACT_APP_WS_ESHOP_BASE_URL;
      fetch(`${baseURL}/authentication/google`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: response.credential,
        }),
      })
        .then((response) => response.json())
        .then(async (data) => {
          const callbackUrlParam = localStorage.getItem("callbackUrl");
          const { accessToken, refreshToken } = data;
          const { allowed_projects: projectList } = await getAllowedProjects(
            accessToken
          );

          setSession(accessToken);

          const isCallbackparamUrlinProjectList = projectList.some(
            (item) => item.fqdn_root === callbackUrlParam
          );

          if (isCallbackparamUrlinProjectList) {
            const params = {
              accessToken,
              refreshToken,
              callbackUrl: callbackUrlParam || undefined,
            };

            const encryptedParams = await encryptData(params);
            window.location.replace(
              `https://${callbackUrlParam}/login?params=${encryptedParams}`
            );
          } else if (projectList.length === 1) {
            const domain = projectList[0]?.fqdn_root;
            const params = {
              accessToken,
              refreshToken,
              callbackUrl: domain || undefined,
            };

            const encryptedParams = await encryptData(params);
            window.location.replace(
              `https://${domain}/login?params=${encryptedParams}`
            );
          } else if (projectList.length > 1) {
            // Redirect to the "project-list" page with state
            navigate(PATH_AUTH.projectsList, {
              state: { projectList, accessToken, refreshToken },
            });
          } else if (
            projectList.length < 1 ||
            !isCallbackparamUrlinProjectList
          ) {
            dispatch({ type: "NO_PROJECT" });
          } else {
            // Handle the case when none of the conditions are met
            // You can add appropriate logic here.
          }
        });
    },
    [navigate]
  );
  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: "jwt",
      login,
      register,
      logout,
      isProjectListEmpty: state.isProjectListEmpty,
      googleLogin,
      dispatch,
      loading: state.loading,
      error: state.error,
      clearError,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      logout,
      register,
      state.isProjectListEmpty,
      googleLogin,
      state.loading,
      state.error,
      clearError,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
