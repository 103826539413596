import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_WS_ESHOP_BASE_URL,
});

// Create a variable to keep track of the number of active requests
let activeRequests = 0;

// Function to increment activeRequests and show the spinner
const showSpinner = () => {
  activeRequests++;
  // When activeRequests increase, you can set a flag in your application state
};

const hideSpinner = () => {
  activeRequests--;
  if (activeRequests === 0) {
    // When activeRequests reach 0, you can set a flag to hide the spinner.
  }
};

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    showSpinner();
    config.headers.Authorization = `ApiKey ${process.env.REACT_APP_WS_ESHOP_API_KEY}`;
    return config;
  },
  (error) => {
    hideSpinner();
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    hideSpinner();
    return response;
  },
  (error) => {
    hideSpinner();
    return Promise.reject(error);
  }
);

export default axiosInstance;
