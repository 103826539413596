import { Navigate, useRoutes } from "react-router-dom";
// auth
// import AuthGuard from '../auth/AuthGuard';
import GuestGuard from "../auth/GuestGuard";

import CompactLayout from "../layouts/compact";
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  ProjectsListPage,
  Page500,
  Page403,
  Page404,
  MaintenancePage,
} from "./elements";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: "login",
      element: (
        <GuestGuard>
          <LoginPage />
        </GuestGuard>
      ),
    },
    {
      path: "register",
      element: (
        <GuestGuard>
          <RegisterPage />
        </GuestGuard>
      ),
    },
    { path: "login-unprotected", element: <LoginPage /> },
    { path: "register-unprotected", element: <RegisterPage /> },
    { path: "projects-list", element: <ProjectsListPage /> },
    {
      element: <CompactLayout />,
      children: [
        { path: "reset-password", element: <ResetPasswordPage /> },
        { path: "new-password", element: <NewPasswordPage /> },
        { path: "verify", element: <VerifyCodePage /> },
      ],
    },

    {
      element: <CompactLayout />,
      children: [
        { path: "maintenance", element: <MaintenancePage /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
      ],
    },
    { path: "/", element: <Navigate to="/login" replace /> }, //Anasayfaya gelirse de login e yonlensin
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
