export const PATH_AUTH = {
  login: "/login",
  register: "/register",
  loginUnprotected: "/login-unprotected",
  registerUnprotected: "/register-unprotected",
  verify: "/verify",
  resetPassword: "/reset-password",
  newPassword: "/new-password",
  projectsList: "/projects-list",
};

export const PATH_PAGE = {
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
};
